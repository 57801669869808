import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import axios from "axios";
import Recaptcha from "react-recaptcha";
import "./JoinNowForm.css";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";

const scrollToRef = () => window.scrollTo(0, 0);

const JoinNowForm = () => {
  const alert = useAlert();

  const [fullNameState, setFullNameState] = useState("");
  const [phoneNoState, setPhoneNoState] = useState("");
  const [emailState, setEmailState] = useState("");
  const [locationState, setLocationState] = useState("");
  const [occupationState, setOccupationState] = useState("");
  const [captchaState, setCaptchaState] = useState(false);
  const [captchaKeyState, setCaptchaKeyState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setErrorState] = useState({});

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };

  const validatePhoneNo = (phoneNo) => {
    const re = /^\d{10}$/;
    return re.test(phoneNo.toLowerCase());
  };

  const validateDetails = () => {
    const errors = {};
    if (!fullNameState) {
      errors["fullNameState"] = "Required";
    }
    if (!phoneNoState || !validatePhoneNo(phoneNoState)) {
      errors["phoneNoState"] = "Required";
    }
    if (!emailState || !validateEmail(emailState)) {
      errors["emailState"] = "Required";
    }
    if (!locationState) {
      errors["locationState"] = "Required";
    }
    if (!occupationState) {
      errors["occupationState"] = "Required";
    }
    if (!captchaState) {
      errors["captchaState"] = "Required";
    }
    return errors;
  };

  const handleEnquireClick = () => {
    setIsLoading(true);
    try {
      const errors = validateDetails();
      // console.log("errors - ", errors);
      if (Object.keys(errors).length !== 0) {
        setErrorState(errors);
        setIsLoading(false);
        return;
      } else {
        setErrorState({});
      }
      postJoinNowFormData();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const postJoinNowFormData = () => {
    let body = {
      recaptchaResponse: captchaKeyState,
      fullName: fullNameState,
      mobileNumber: phoneNoState,
      emailId: emailState,
      location: locationState,
      currentOccupation: occupationState,
    };

    axios
      .post("https://api.start-blocks.com/landing-page/lpc", body)
      .then((response) => {
        console.log("response - ", response);
        setIsLoading(false);
        clearTextBox();
        alert.success(
          "Thanks for showing your interest. We will get back to you shortly."
        );
      })
      .catch((error) => {
        console.log("error - ", error);
        setIsLoading(false);
        clearTextBox();
        alert.error("Something went wrong, please try again later.");
      });
  };

  const clearTextBox = () => {
    setFullNameState("");
    setPhoneNoState("");
    setEmailState("");
    setLocationState("");
    setOccupationState("");
    setCaptchaState("");
  };

  useEffect(() => {
    scrollToRef();
  }, []);

  const history = useHistory();

  const onCaptchaChange = (response) => {
    setCaptchaKeyState(response);
    setCaptchaState(true);
  };

  const handleTextBoxChange = (e) => {
    if (e.target.name === "fullName") {
      setFullNameState(e.target.value);
    }
    if (e.target.name === "phoneNo") {
      setPhoneNoState(e.target.value);
    }
    if (e.target.name === "emailId") {
      setEmailState(e.target.value);
    }
    if (e.target.name === "location") {
      setLocationState(e.target.value);
    }
    if (e.target.name === "occupation") {
      setOccupationState(e.target.value);
    }
  };

  const handlePrivacyClick = () => {
    history.push("/privacy-policy");
  };

  return (
    <>
      <NavBar isFromScholarship={true} />
      <div className="scholarShipForm">
        <div className="scholarShipFormHeaderText">
          Signup now for a great career opportunity
        </div>
        <div className="scholarshipForm">
          <div className="w3-row">
            <div className="w3-half scholarShipFormSection">
              <div className="formSectionHeading">What’s your name?</div>
              <input
                onChange={handleTextBoxChange}
                className={`w3-input  getInTouchFormInputBox ${
                  errorState["fullNameState"] && "textBoxError"
                }`}
                name="fullName"
                value={fullNameState}
                type="text"
                placeholder="Full Name"
              />
            </div>
            <div className="w3-half scholarShipFormSection">
              <div className="formSectionHeading">
                What’s your phone number?
              </div>
              <input
                onChange={handleTextBoxChange}
                className={`w3-input  getInTouchFormInputBox ${
                  errorState["phoneNoState"] && "textBoxError"
                }`}
                name="phoneNo"
                value={phoneNoState}
                type="text"
                placeholder="Mobile No"
              />
            </div>
          </div>
          <div className="w3-row">
            <div className="w3-half scholarShipFormSection">
              <div className="formSectionHeading">What’s your email?</div>
              <input
                onChange={handleTextBoxChange}
                name="emailId"
                value={emailState}
                className={`w3-input  getInTouchFormInputBox ${
                  errorState["emailState"] && "textBoxError"
                }`}
                type="text"
                placeholder="Email ID"
              />
            </div>
            {/* <div className="w3-half scholarShipFormSection">
                <div className="formSectionHeading">
                  What do you need help with
                </div>
                <select class="w3-select getInTouchFormInputBox" name="option">
                  <option value="" disabled selected>
                    Choose your option
                  </option>
                  <option value="1">Option 1</option>
                  <option value="2">Option 2</option>
                  <option value="3">Option 3</option>
                </select>
              </div> */}
            <div className="w3-half scholarShipFormSection">
              <div className="formSectionHeading">What’s your location</div>
              <input
                onChange={handleTextBoxChange}
                name="location"
                className={`w3-input  getInTouchFormInputBox ${
                  errorState["locationState"] && "textBoxError"
                }`}
                value={locationState}
                type="text"
                placeholder="Location"
              />
            </div>
          </div>
          <div className="scholarShipFormSection">
            <div className="formSectionHeading">Current Occupation</div>
            <input
              onChange={handleTextBoxChange}
              name="occupation"
              value={occupationState}
              className={`w3-input  getInTouchFormInputBox ${
                errorState["occupationState"] && "textBoxError"
              }`}
              type="text"
              placeholder="Current Occupation"
            />
          </div>
          <div className="reCaptchaContainer">
            <div
              className={`reCaptchaInJoinNow ${
                errorState["captchaState"] && "captchaError"
              }`}
            >
              <Recaptcha
                sitekey="6Ld7RU8bAAAAAJ-LZ3N3PtWl0pHzE1AK7G1ccUbj"
                render="explicit"
                theme="dark"
                verifyCallback={onCaptchaChange}
              />
            </div>
          </div>
          <div className="scholarShipFormSection">
            <div className="disclaimer">
              I agree to Startblocks Terms and{" "}
              <span className="privacyPolicyLink" onClick={handlePrivacyClick}>
                privacy policy
              </span>{" "}
              Please contact me by phone, email or SMS to assist with my enquiry
              I’d love to get useful emails from Startblocks about the
              opportunity and updates
            </div>
            <button
              class="w3-button primaryButton w3-hide-small w3-hide-medium"
              onClick={handleEnquireClick}
              style={{ marginTop: "2%" }}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Enquire"}
            </button>
            <button
              class="w3-button primaryButton w3-block w3-hide-large"
              onClick={handleEnquireClick}
              style={{ marginTop: "2%" }}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Enquire"}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JoinNowForm;

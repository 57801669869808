import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import "./App.css";
//import NavBar from "./Components/NavBar/NavBar";
import HomePage from "./Components/HomePage/HomePage";
//import Footer from "./Components/Footer/Footer";
import ForPartners from "./Components/ForPartners/ForPartners";
import ScholarShipsForm from "./Components/ScholarShipsForm/ScholarShipsForm";
import JoinNowForm from "./Components/JoinNowForm/JoinNowForm";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import Terms from "./Components/Terms/Terms";
import CookiesPolicy from "./Components/CookiesPolicy/CookiesPolicy";

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};

function App() {
  return (
    <Router>
      <Provider template={AlertTemplate} {...options}>
        <div className="App">
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/Home" exact component={HomePage} />
            <Route path="/Home/:section" component={HomePage} />
            <Route path="/get-in-touch/" component={ForPartners} />
            <Route path="/scholarships" component={ScholarShipsForm} />
            <Route path="/join-now" component={JoinNowForm} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms" component={Terms} />
            <Route path="/cookies-policy" component={CookiesPolicy} />
          </Switch>
        </div>
      </Provider>
    </Router>
  );
}

export default App;

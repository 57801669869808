import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Recaptcha from "react-recaptcha";
import axios from "axios";
import logo from "../../Assets/imges/index-blue.png";
import "./ForPartners.scss";

const scrollToRef = () => window.scrollTo(0, 0);

const ForPartners = ({match, location}) => {
  const alert = useAlert();

  const [selectedOption, setSelectedOption] = useState("");
  const [userTypeState, setUserTypeState] = useState("");
  const [nameOfCompanyInstituteState, setNameOfCompanyInstituteState] =
    useState("");
  const [firstNameState, setFirstNameState] = useState("");
  const [lastNameState, setLastNameState] = useState("");
  const [phoneNoState, setPhoneNoState] = useState("");
  const [emailState, setEmailState] = useState("");
  const [enquiryDetailsState, setEnquiryDetailsState] = useState("");
  const [cityState, setCityState] = useState("");
  const [occupationState, setOccupationState] = useState("");
  const [captchaState, setCaptchaState] = useState(false);
  const [captchaKeyState, setCaptchaKeyState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setErrorState] = useState({});
  const [isCaptchaLoaded,setIsCaptchaLoaded]=useState(false);
  const [captchaCounter,setCaptchaCounter]=useState(0);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };

  const validatePhoneNo = (phoneNo) => {
    const re = /^\d{10}$/;
    return re.test(phoneNo.toLowerCase());
  };

  const validateDetails = () => {
    console.log("userTypeState - ", userTypeState);
    const errors = {};
    if (!userTypeState) {
      errors["userTypeState"] = "Required";
    }
    if (userTypeState === "LPC") {
      if (!firstNameState) {
        errors["firstNameState"] = "Required";
      }
      if (!lastNameState) {
        errors["lastNameState"] = "Required";
      }
      if (!phoneNoState || !validatePhoneNo(phoneNoState)) {
        errors["phoneNoState"] = "Required";
      }
      if (!emailState || !validateEmail(emailState)) {
        errors["emailState"] = "Required";
      }
      if (!cityState) {
        errors["cityState"] = "Required";
      }
      if (!occupationState) {
        errors["occupationState"] = "Required";
      }
      if (!captchaState) {
        errors["captchaState"] = "Required";
      }
    } else {
      if (!nameOfCompanyInstituteState) {
        errors["nameOfCompanyInstituteState"] = "Required";
      }
      if (!firstNameState) {
        errors["firstNameState"] = "Required";
      }
      if (!lastNameState) {
        errors["lastNameState"] = "Required";
      }
      if (!phoneNoState || !validatePhoneNo(phoneNoState)) {
        errors["phoneNoState"] = "Required";
      }
      if (!emailState || !validateEmail(emailState)) {
        errors["emailState"] = "Required";
      }
      if (!enquiryDetailsState) {
        errors["enquiryDetailsState"] = "Required";
      }
      if (!captchaState) {
        errors["captchaState"] = "Required";
      }
    }
    return errors;
  };

  const handleSubmitClick = () => {
    setIsLoading(true);
    try {
      const errors = validateDetails();
      // console.log("errors - ", errors);
      if (Object.keys(errors).length !== 0) {
        setErrorState(errors);
        setIsLoading(false);
        return;
      } else {
        setErrorState({});
      }
      postPartnerFormData();
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(()=>{
    if(!isCaptchaLoaded){
      setCaptchaCounter(captchaCounter+1);
    }
  },[captchaCounter,isCaptchaLoaded])

  const handleCaptchaLoad = ()=>{
    setIsCaptchaLoaded(true);
  }

  const postPartnerFormData = () => {
    if (userTypeState === "LPC") {
      let body = {
        firstName: firstNameState,
        lastName: lastNameState,
        city: cityState,
        phoneNumber: phoneNoState,
        email: emailState,
        occupation: occupationState,
      };

      axios
        .post("https://api.start-blocks.com/landing-page/lpo", body)
        .then((response) => {
          console.log("response - ", response);
          setIsLoading(false);
          clearTextBox();
          alert.success(
            "Thanks for showing your interest. We will get back to you shortly."
          );
        })
        .catch((error) => {
          console.log("error - ", error);
          setIsLoading(false);
          clearTextBox();
          alert.error("Something went wrong, please try again later.");
        });
    } else {
      let body = {
        recaptchaResponse: captchaKeyState,
        role: userTypeState,
        firstName: firstNameState,
        lastName: lastNameState,
        nameOfCompany: nameOfCompanyInstituteState,
        mobileNumber: phoneNoState,
        emailId: emailState,
        enquiryDetails: enquiryDetailsState,
      };

      axios
        .post("https://api.start-blocks.com/landing-page/details", body)
        .then((response) => {
          console.log("response - ", response);
          setIsLoading(false);
          clearTextBox();
          alert.success(
            "Thanks for showing your interest. We will get back to you shortly."
          );
        })
        .catch((error) => {
          console.log("error - ", error);
          setIsLoading(false);
          clearTextBox();
          alert.error("Something went wrong, please try again later.");
        });
    }
  };

  const clearTextBox = () => {
    setNameOfCompanyInstituteState("");
    setFirstNameState("");
    setLastNameState("");
    setPhoneNoState("");
    setEmailState("");
    setEnquiryDetailsState("");
    setCityState("");
    setCaptchaState(false);
  };

  const history = useHistory();

  const onCaptchaChange = (response) => {
    setCaptchaKeyState(response);
    setCaptchaState(true);
  };

  const handleLogoClick = () => {
    history.push("/");
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    setUserTypeState(e.target.value);
  };

  const handleTextBoxChange = (e) => {
    if (e.target.name === "companyInstitution") {
      setNameOfCompanyInstituteState(e.target.value);
    }
    if (e.target.name === "firstName") {
      setFirstNameState(e.target.value);
    }
    if (e.target.name === "lastName") {
      setLastNameState(e.target.value);
    }
    if (e.target.name === "phoneNo") {
      setPhoneNoState(e.target.value);
    }
    if (e.target.name === "emailId") {
      setEmailState(e.target.value);
    }
    if (e.target.name === "enquiryDetails") {
      setEnquiryDetailsState(e.target.value);
    }
    if (e.target.name === "city") {
      setCityState(e.target.value);
    }
    if (e.target.name === "occupation") {
      setOccupationState(e.target.value);
    }
  };

  const handlePrivacyClick = () => {
    history.push("/privacy-policy");
  };

  useEffect(() => {
    scrollToRef();
    setEmailState(sessionStorage.getItem("mailID"));
    sessionStorage.removeItem("mailID");
    if(location.search){
      if(location.search.split("=")[1]==="lpo"){
        setSelectedOption("LPC");
        setUserTypeState("LPC");
      }
    }
  }, [location.search]);

  return (
    <div className="getinTouchContainer">
      <div className="w3-row getinTouchSubContainer">
        <div className="w3-half getInTouchformContainer">
          <div className="formHeading">
            Process more applications in less time. Gain transparency and
            integrity in every application.
          </div>
          <div className="formSubHeading">
            If you wish to enquire joining our growing network of Institutions, Recruitment partners or LPOs, please share some details
          </div>
          <div className="getInTouchForm">
            <div className="w3-row">
              <div className="roleDropdown">
                <select
                  className={`w3-select  getInTouchFormInputBox ${
                    errorState["userTypeState"] && "textBoxError"
                  }`}
                  style={{ paddingLeft: "7px" }}
                  name="option"
                  onChange={handleSelectChange}
                  value={selectedOption==="LPC"?"LPC":selectedOption}
                >
                  <option value="" disabled selected>
                    Are you a/an
                  </option>
                  <option value="Institution">Institution</option>
                  <option value="recruitmentPartner">
                    Recruitment Partner
                  </option>
                  <option value="LPC">LPO</option>
                </select>
              </div>
              {selectedOption !== "LPC" && (
                <div>
                  <input
                    onChange={handleTextBoxChange}
                    value={nameOfCompanyInstituteState}
                    name="companyInstitution"
                    className={`w3-input  getInTouchFormInputBox ${
                      errorState["nameOfCompanyInstituteState"] &&
                      "textBoxError"
                    }`}
                    type="text"
                    placeholder="Name of company/institution"
                  />
                </div>
              )}
              <div className="w3-half rightSideTextBox">
                <input
                  onChange={handleTextBoxChange}
                  value={firstNameState}
                  name="firstName"
                  className={`w3-input  getInTouchFormInputBox ${
                    errorState["firstNameState"] && "textBoxError"
                  }`}
                  type="text"
                  placeholder="First Name"
                />
              </div>
              <div className="w3-half leftSideTextBox">
                <input
                  onChange={handleTextBoxChange}
                  value={lastNameState}
                  name="lastName"
                  className={`w3-input  getInTouchFormInputBox ${
                    errorState["lastNameState"] && "textBoxError"
                  }`}
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            </div>
            {/* <div className="w3-row">
              <div className="w3-half rightSideTextBox">
                <input
                  class="w3-input  getInTouchFormInputBox"
                  type="text"
                  placeholder="Company"
                />
              </div>
              <div className="w3-half leftSideTextBox">
                <input
                  class="w3-input getInTouchFormInputBox"
                  type="text"
                  placeholder="Location"
                />
              </div>
            </div> */}
            <div className="w3-row">
              <div className="w3-half rightSideTextBox">
                <input
                  value={phoneNoState}
                  onChange={handleTextBoxChange}
                  name="phoneNo"
                  className={`w3-input  getInTouchFormInputBox ${
                    errorState["phoneNoState"] && "textBoxError"
                  }`}
                  type="text"
                  placeholder="Contact Phone"
                />
              </div>
              <div className="w3-half leftSideTextBox">
                <input
                  value={emailState}
                  onChange={handleTextBoxChange}
                  name="emailId"
                  className={`w3-input  getInTouchFormInputBox ${
                    errorState["emailState"] && "textBoxError"
                  }`}
                  type="text"
                  placeholder="Work Email"
                />
              </div>
            </div>

            {selectedOption !== "LPC" ? (
              <input
                onChange={handleTextBoxChange}
                value={enquiryDetailsState}
                name="enquiryDetails"
                className={`w3-input  getInTouchFormInputBox ${
                  errorState["enquiryDetailsState"] && "textBoxError"
                }`}
                type="text"
                placeholder="Enquiry details"
              />
            ) : (
              <input
                onChange={handleTextBoxChange}
                value={cityState}
                name="city"
                className={`w3-input  getInTouchFormInputBox ${
                  errorState["cityState"] && "textBoxError"
                }`}
                type="text"
                placeholder="City"
              />
            )}
            {selectedOption === "LPC" && (
              <div>
                <input
                  onChange={handleTextBoxChange}
                  value={occupationState}
                  name="occupation"
                  className={`w3-input  getInTouchFormInputBox ${
                    errorState["occupationState"] && "textBoxError"
                  }`}
                  type="text"
                  placeholder="Occupation"
                />
              </div>
            )}
            <div
              className={`reCaptcha ${
                errorState["captchaState"] && "captchaError"
              }`}
            >
              <Recaptcha
                sitekey="6Ld7RU8bAAAAAJ-LZ3N3PtWl0pHzE1AK7G1ccUbj"
                render="explicit"
                theme="dark"
                key={captchaCounter}
                verifyCallback={onCaptchaChange}
                onloadCallback={handleCaptchaLoad}
              />
            </div>
            <button
              onClick={handleSubmitClick}
              class="w3-button primaryButton w3-block"
              style={{ marginTop: "2%" }}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Submit"}
            </button>
            <div className="disclaimer">
              By Submitting this information, you agree to StartBlocks{" "}
              <span className="privacyPolicyLink" onClick={handlePrivacyClick}>
                Privacy Policy
              </span>
              , and you consent to have this information be with us for
              communication purposes.
            </div>
          </div>
        </div>
        <div className="w3-half righSideContainer">
          <div className="logoContainerForPartner">
            <img
              src={logo}
              alt="Start-Blocks-Logo"
              className="logoImageForPartner w3-hide-small"
              onClick={handleLogoClick}
            />
          </div>
          {selectedOption === "recruitmentPartner" ? (
            <div className="forPartnerRightSideContent animate__animated animate__fadeIn">
              <div className="pointsHeader">Recruitment partners</div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>On time commission gurantee.</span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>
                  Full transparency on the status of application even after
                  student gets enrolled.
                </span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>You have right to know and speak with us anytime.</span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>
                  Work with us and our operational team to enable and process
                  your visa application faster.
                </span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>
                  Increase your chances by atleast 50% on visa success rate with
                  help of our platform.
                </span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>
                  Real time insights on your application with quality dashboard.
                </span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>
                  Your application is always yours. We use state-of-the-art
                  secured infrastructure which is tested globally.
                </span>
              </div>
            </div>
          ) : selectedOption === "Institution" ? (
            <div className="forPartnerRightSideContent animate__animated animate__fadeIn">
              <div className="pointsHeader">Institutions</div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>Real time insights for the application.</span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>
                  Work along with your preferred recruitment partner or
                  organisation.
                </span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>Gain transparency in the application processing.</span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>
                  Integrate StartBlocks with your software seamlessly for
                  application processing.
                </span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>
                  Have traction to the application with focussed group chats.
                </span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>Subscribe to optional reporting.</span>
              </div>
            </div>
          ) : (
            <div className="forPartnerRightSideContent animate__animated animate__fadeIn">
              <div className="pointsHeader">Benefits</div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>
                  <b>Training & Certificate:</b> Get an International
                  Certificate for the startblocks Global LPO Training.{" "}
                </span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>
                  <b>Flexible Working hours:</b> work at your convenience, chose
                  your work hours.
                </span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>
                  <b>Highly Rewarding:</b> Earn INR 5 Lacs+ by enrolling 10
                  friends / followers.
                </span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>
                  <b>Self-Learning & gaining knowledge:</b> Learn LIVE from the
                  market leaders about overseas education & get hands on
                  experience.
                </span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>
                  <b>Become an Edtech influencer:</b> With the startblocks LPO
                  role also get a chance to work with other non-competitive
                  education brands & improve your earning’s.
                </span>
              </div>
              <div className="point">
                <i class="fa fa-check tickIcon" aria-hidden="true"></i>{" "}
                <span>
                  <b>On job experience:</b> Gain on job experience working as a
                  startblocks LPO.{" "}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForPartners;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./NavBar.css";
import MobileNavBar from "../MobileNavBar/MobileNavBar";
import logo from "../../Assets/imges/index-blue.png";

const NavBar = (props) => {
  const history = useHistory();

  const handleGetInTouch = () => {
    history.push("/get-in-touch");
  };

  const handleScholarshipClick = () => {
    history.push("/scholarships");
  };

  const handleLogoClick = () => {
    history.push("/");
  };

  const [isMobileNavBarVisibleState, setIsMobileNavBarVisibleState] =
    useState(false);

  const closeMobileNavBar = () => {
    setIsMobileNavBarVisibleState(false);
  };

  const handlePlatformClick = () => {
    if (props.isFromScholarship === false) {
      props.handleScrollToPlatformProps();
    } else {
      history.push("/Home/platform");
    }
  };

  const handleRecruitementPartnerClick = () => {
    if (props.isFromScholarship === false) {
      props.handleScrollToRecruitementPartnersProps();
    } else {
      history.push("/Home/recruitement-partners");
    }
  };

  const handleInstitutionClick = () => {
    if (props.isFromScholarship === false) {
      props.handleScrollToInstitutionsProps();
    } else {
      history.push("/Home/institutions");
    }
  };

  return (
    <div className="navBarContianer w3-row">
      {isMobileNavBarVisibleState && (
        <MobileNavBar
          closeMobileNavBar={closeMobileNavBar}
          isFromScholarship={props.isFromScholarship}
          handleScrollToPlatformProps={props.handleScrollToPlatformProps}
          handleScrollToInstitutionsProps={
            props.handleScrollToInstitutionsProps
          }
          handleScrollToRecruitementPartnersProps={
            props.handleScrollToRecruitementPartnersProps
          }
        />
      )}
      <div className="logoContainer w3-col" style={{ width: "25%" }}>
        <img
          src={logo}
          alt=""
          className="logoImage"
          onClick={handleLogoClick}
        />
      </div>
      <div
        className="w3-hide-large w3-col"
        style={{ width: "70%", textAlign: "right" }}
      >
        <i
          class="fa fa-bars hamburgerIcon"
          onClick={() => {
            setIsMobileNavBarVisibleState(true);
          }}
        ></i>
      </div>
      <div
        className="navBarLinksContainer w3-hide-small w3-hide-medium w3-col marginTop"
        style={{ width: "50%" }}
      >
        <span
          className="navBarLinkItem marginTop"
          style={{ marginLeft: "-30px" }}
          onClick={handlePlatformClick}
        >
          Platform
        </span>
        <span
          className="navBarLinkItem"
          onClick={handleRecruitementPartnerClick}
        >
          Recruitment Partners
        </span>
        <span className="navBarLinkItem" onClick={handleInstitutionClick}>
          Institutions
        </span>
        <span className="navBarLinkItem" onClick={handleScholarshipClick}>
          Scholarships
        </span>
      </div>
      <div
        className="loginSignupCOntainer w3-hide-small w3-hide-medium w3-col"
        style={{ width: "25%" }}
      >
        <div className="w3-row">
          <div className="w3-half marginTop" style={{ textAlign: "right" }}>
            <a
              href="https://app.startblocks.com"
              className="navBarLinkItem"
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              Sign In
            </a>
          </div>
          <div className="w3-rest">
            <button
              class="w3-button primaryButton navBarButton"
              onClick={handleGetInTouch}
            >
              Get in touch
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;

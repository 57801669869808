import React from "react";
//import { useHistory } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import "./ForInstitution.css";

const ForInstitution = () => {
  //const history = useHistory();

  // const handleGetInTouch = () => {
  //   history.push("/get-in-touch");
  // };
  return (
    <div className="forInstitutionContainer">
      <div className="forInstitutionContent w3-row">
        <div className="w3-half w3-hide-small w3-hide-medium">
          <div className="forInstitutionLottieImageContainer">
            <Player
              autoplay
              loop
              src="https://s3.ap-southeast-2.amazonaws.com/json.start-blocks.com/forInstitutesUpdated.json"
              style={{
                height: window.innerWidth < 400 ? "100px" : "40vw",
                width: window.innerWidth < 400 ? "100px" : "40vw",
              }}
            />
          </div>
        </div>
        <div className="w3-half forInstitutionTextContainer">
          <div className="sectionHeading">Institutes</div>
          <div className="forInstitutionkHeadingText">
            Transparency and Integrity in every application
          </div>
          <div className="forInstitutionSubHeadingText">
            Collaborate with the recruitment partners, organizations and
            students for application processing with automated workflows and
            insights ensuring 100% transparency and integrity in the
            application. To know more - get in touch!
          </div>
          {/* <div className="getInTouchInInstitute">
            <div className="w3-row">
              <div className="w3-threequarter getInTouchWriting">
                Become our trusted recruitment partner
              </div>
              <div className="w3-quarter">
                <button
                  class="w3-button primaryButton navBarButton"
                  onClick={handleGetInTouch}
                >
                  Get in touch
                </button>
              </div>
            </div>
          </div> */}
        </div>
        <div className="w3-half w3-hide-large">
          <div className="forInstitutionLottieImageContainer">
            <Player
              autoplay
              loop
              src="https://s3.ap-southeast-2.amazonaws.com/json.start-blocks.com/forInstitutesUpdated.json"
              style={{
                height: "300px",
                width: "300px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForInstitution;

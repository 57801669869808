import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import "./OneFramework.css";

const OneFramework = () => {
  return (
    <div className="oneFrameworkContainer">
      <div className="oneFrameworkContent w3-row">
        <div className="w3-half leftTextContainer">
          <div className="sectionHeading">Platform</div>
          <div className="oneFrameworkHeadingText">
            One Framework. Fits All.
          </div>
          <div className="oneFrameworkSubHeadingText">
            StartBlocks built with dynamic framework allow any application from
            any country to be processed without any additional effort. With
            “auto-adjust “feature, the blocks configure themselves with
            intelligent routes creating best case scenario for application
            processing.
          </div>
        </div>
        <div className="w3-half">
          <div className="OneFrameworkLottieImageContainer">
            <Player
              autoplay
              loop
              src="https://s3.ap-southeast-2.amazonaws.com/json.start-blocks.com/oneFrameworkUpdated.json"
              style={{
                height: window.innerWidth < 400 ? "300px" : "30vw",
                width: window.innerWidth < 400 ? "300px" : "30vw",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneFramework;

import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import "./OurStrength.css";

import globalAgent from "../../Assets/imges/globalAgent.png";
import studentBase from "../../Assets/imges/studentBase.png";
import visa from "../../Assets/imges/visa.png";
//import counsellors from "../../Assets/imges/counsellors.png";
//import application from "../../Assets/imges/application.png";

const OurStrength = () => {
  const [isComponentVisibleState, setIsComponentVisibleState] = useState(0);

  const handleOnScroll = () => {
    setIsComponentVisibleState(isComponentVisibleState + 1);
  };

  //strengthComponent.addEventListener("scroll", handleOnScroll);

  return (
    <VisibilitySensor onChange={handleOnScroll}>
      <div className="ourStrengthContainer">
        <div className="ourStrengthHeader">
          <div className="ourStrengthHeadingText">
            Made up with 10+ years of application processing experience
          </div>
          <div className="ourStrengthSubHeadingText">
            StartBlocks has been designed by the people who have worked in
            application processing at the ground level achieving exceptional
            visa success rates. The proprietary knowledge meets practical
            design.
          </div>
        </div>
        <div
          className={` w3-row ${
            isComponentVisibleState >= 2
              ? "animate__animated animate__slideInUp ourStrengthBodyContainerShow"
              : "ourStrengthBodyContainer"
          }`}
        >
          <div
            className="w3-col s12 strengthCardContainer"
            style={{ width: "33.3%" }}
          >
            <div className="strengthCard">
              {/*<div className="iconBox">
              <i class="fa fa-group"></i>
            </div>
            <span className="focusedWord">1000 +</span>
            <br /> Global Agent Network*/}
              <img
                src={globalAgent}
                alt="global agent"
                className="strengthcardImage"
              />
            </div>
          </div>
          <div
            className="w3-col s12 strengthCardContainer"
            style={{ width: "33.3%" }}
          >
            <div className="strengthCard">
              {/*<div className="iconBox">
              <i class="fa fa-graduation-cap"></i>
            </div>
            Direct Access to Over
            <br />
            <span className="focusedWord">3 Mn</span>
            <br /> Students base*/}
              <img
                src={studentBase}
                alt="global agent"
                className="strengthcardImage"
              />
            </div>
          </div>
          <div
            className="w3-col s12 strengthCardContainer"
            style={{ width: "33.3%" }}
          >
            <div className="strengthCard">
              {/*<div className="iconBox">
              <i class="fa fa-plane"></i>
            </div>
            <span className="focusedWord">98%</span>
            <br /> Visa Success*/}
              <img
                src={visa}
                alt="global agent"
                className="strengthcardImage"
              />
            </div>
          </div>
        </div>

        <div className="ourStrengthBodyContainerMobile w3-row">
          <div className="w3-col s12 strengthCardContainer">
            <div className="strengthCard">
              {/*<div className="iconBox">
              <i class="fa fa-group"></i>
            </div>
            <span className="focusedWord">1000 +</span>
            <br /> Global Agent Network*/}
              <img
                src={globalAgent}
                alt="global agent"
                className="strengthcardImage"
              />
            </div>
          </div>
          <div className="w3-col s12 strengthCardContainer">
            <div className="strengthCard">
              {/*<div className="iconBox">
              <i class="fa fa-graduation-cap"></i>
            </div>
            Direct Access to Over
            <br />
            <span className="focusedWord">3 Mn</span>
            <br /> Students base*/}
              <img
                src={studentBase}
                alt="global agent"
                className="strengthcardImage"
              />
            </div>
          </div>
          <div className="w3-col s12 strengthCardContainer">
            <div className="strengthCard">
              {/*<div className="iconBox">
              <i class="fa fa-plane"></i>
            </div>
            <span className="focusedWord">98%</span>
            <br /> Visa Success*/}
              <img
                src={visa}
                alt="global agent"
                className="strengthcardImage"
              />
            </div>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default OurStrength;

import React from "react";
import { useHistory } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  const history = useHistory();

  const handlePrivacyClick = () => {
    history.push("/privacy-policy");
  };

  const handleTermsClick = () => {
    history.push("/terms");
  };

  const handleCookiesClick = () => {
    history.push("/cookies-policy");
  };

  return (
    <div className="footerContainer">
      <div className="footerSubContainer">
        <div className="w3-hide-small w3-hide-medium">
          <div className="w3-row">
            <div className="w3-third leftContents">
              <span className="footerItem" onClick={handlePrivacyClick}>
                Privacy <i class="fa fa-circle dotIcon"></i>
              </span>
              <span className="footerItem" onClick={handleTermsClick}>
                Terms <i class="fa fa-circle dotIcon"></i>
              </span>
              <span className="footerItem" onClick={handleCookiesClick}>
                Cookies Policy
              </span>
            </div>

            <div
              className="w3-rest w3-hide-small"
              style={{ textAlign: "right" }}
            >
              <span className="footerItem">
                16 Great Queen Street, Covent Garden, London, WC2B 5AH <i class="fa fa-circle dotIcon"></i>
              </span>
              <span className="footerItem">hello@startblocks.com</span>
              {/* <span className="footerItem">Some Street, UK</span> */}
            </div>
          </div>

          <div className="secondLine w3-row">
            <div className="w3-threequarter">
              <span className="footerItem">
                {" "}
                <i class="fa fa-copyright"></i> 2021 Australia Software &
                Technology Solutions Pty Ltd. All Rights reserved
              </span>
            </div>
            <div className="w3-rest" style={{ textAlign: "right" }}>
              <span className="footerItem">
                <a
                  href="https://www.linkedin.com/company/startblocks/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fa fa-linkedin" aria-hidden="true"></i>
                </a>
              </span>
              <span className="footerItem">
                <a
                  href="https://twitter.com/startblocks"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fa fa-twitter"></i>
                </a>
              </span>
              <span className="footerItem">
                <a
                  href="https://www.facebook.com/Startblocks-109699601358285"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fa fa-facebook-square" aria-hidden="true"></i>
                </a>
              </span>
              <span className="footerItem">
                <a
                  href="https://www.instagram.com/startblocks/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="w3-third leftContents w3-hide-large">
          <div className="footerItem" onClick={handlePrivacyClick}>
            Privacy
          </div>
          <div className="footerItem" onClick={handleTermsClick}>
            Terms
          </div>
          <div className="footerItem" onClick={handleCookiesClick}>
            Cookies Policy
          </div>
          <div className="footerItem">16 Great Queen Street, Covent Garden, London, WC2B 5AH</div>
          <div className="footerItem">hello@startblocks.com</div>
          <div className="footerItem">
            <span className="footerItem">
              <a
                href="https://www.linkedin.com/company/startblocks/"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fa fa-linkedin" aria-hidden="true"></i>
              </a>
            </span>
            <span className="footerItem">
              <a
                href="https://twitter.com/startblocks"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fa fa-twitter"></i>
              </a>
            </span>
            <span className="footerItem">
              <a
                href="https://www.facebook.com/Startblocks-109699601358285"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fa fa-facebook-square" aria-hidden="true"></i>
              </a>
            </span>
            <span className="footerItem">
              <a
                href="https://www.instagram.com/startblocks/"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </span>
          </div>
          <div
            className="footerItem"
            style={{ borderTop: "1px solid white", paddingTop: "8%" }}
          >
            <i class="fa fa-copyright"></i> 2021 Australia Software & Technology
            Solutions Pty Ltd. All Rights reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React, { useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import "./WithWithout.css";

const WithWithout = () => {
  const [isWithoutSelectedState, setIsWithoutSelectedState] = useState(false);

  const handleChange = () => {
    setIsWithoutSelectedState(!isWithoutSelectedState);
  };

  return (
    <div className="withWithoutContainer">
      <div className="withWithoutHeader">
        <div className="withWithoutHeadingText">
          How the world looks like with and without StartBlocks
        </div>
        <div className="withWithoutSubHeadingText">
          StartBlocks take the pain away from operation teams by avoiding back
          and forth of the application processign by atl-least 80%. Complex
          scenario get streamlined with proprietary block-based application
          framework that has the ability to process the key work items in chunks
          giving a parallel processing experience.
        </div>
      </div>
      <div className="statusToggleContainer">
        <div class="switch-button">
          <input
            class="switch-button-checkbox"
            type="checkbox"
            onClick={handleChange}
          ></input>
          <label class="switch-button-label" for="">
            <span class="switch-button-label-span">With StartBlocks</span>
          </label>
        </div>
      </div>
      <div className="withWithoutImages">
        {/*<img
          src={withImage}
          className={`withWithoutImage ${isWithoutSelectedState  && "hideImage"}`}
          alt=""
        />*/}
        <div
          className={` ${
            isWithoutSelectedState && "withWithoutImagesSubContainer"
          }`}
        >
          {!isWithoutSelectedState && (
            <Player
              autoplay
              loop
              src="https://s3.ap-southeast-2.amazonaws.com/json.start-blocks.com/withStartBlocksUpdated.json"
              style={{
                height: window.innerWidth < 400 ? "300px" : "500px",
                width: window.innerWidth < 400 ? "300px" : "500px",
              }}
            />
          )}
          {isWithoutSelectedState && (
            <Player
              autoplay
              loop
              src="https://s3.ap-southeast-2.amazonaws.com/json.start-blocks.com/withoutStartBlocksUpdated.json"
              style={{
                height: window.innerWidth < 400 ? "300px" : "900px",
                width: window.innerWidth < 400 ? "300px" : "900px",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WithWithout;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./CoverPage.css";
import coverImage from "../../Assets/imges/coverImage.png";

const CoverPage = () => {
  const history = useHistory();
  const [mailIdState, setMailIdState] = useState("");
  const [mailIdErrorState, setMailIdErrorState] = useState(false);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };

  const handleTextBoxChange = (e) => {
    if (e.target.name === "emailId") {
      setMailIdState(e.target.value);
    }
  };

  const handleGetInTouch = () => {
    if (mailIdState && validateEmail(mailIdState)) {
      setMailIdErrorState(false);
      sessionStorage.setItem("mailID", mailIdState);
      history.push("/get-in-touch");
    } else {
      setMailIdErrorState(true);
    }
  };

  return (
    <div className="w3-row coverPageContainer">
      <div className="w3-half coverPageTextSection">
        <div className="coverPagePrimaryText">
          Enabling Global Mobility & Recruitment for Young Masses
        </div>

        <div className="coverPageInputBox">
          <div className="w3-row">
            <div className="w3-half">
              <input
                onChange={handleTextBoxChange}
                name="emailId"
                class={`w3-input w3-border w3-round emailInputBox ${
                  mailIdErrorState && "emailInputBoxError"
                }`}
                type="text"
                placeholder="Work Email"
              />
            </div>
            <div className="w3-half w3-hide-large">
              <button
                class="w3-button primaryButton w3-block"
                onClick={handleGetInTouch}
              >
                Get in touch
              </button>
            </div>
            <div className="w3-rest w3-hide-small w3-hide-medium">
              <button
                class="w3-button primaryButton"
                onClick={handleGetInTouch}
              >
                Get in touch
              </button>
            </div>
          </div>
        </div>
        <div className="coverPageSecondaryText">
          We are building a “global distributed powerhouse” of application
          processing teams and streamlining the application processes for
          everyone - via our proprietary “block-based collaborative intelligent
          platform” bringing transparency, efficacy and certainty in the student
          application.
        </div>
      </div>
      <div className="w3-half coverPageImageSection">
        <div className="coverPageImageContainer">
          <img src={coverImage} alt="" className="coverPageImage" />
        </div>
      </div>
    </div>
  );
};

export default CoverPage;

import React from "react";
import { useHistory } from "react-router-dom";
import "./MobileNavBar.css";
import logoDark from "../../Assets/imges/index-blue.png";

const MobileNavBar = (props) => {
  const history = useHistory();
  const handleScholarshipClick = () => {
    history.push("/scholarships");
  };

  const handleGetInTouch = () => {
    history.push("/get-in-touch");
  };

  const handleLogoClick = () => {
    history.push("/");
  };

  const handlePlatformClick = () => {
    if (props.isFromScholarship === false) {
      props.closeMobileNavBar();
      props.handleScrollToPlatformProps();
    } else {
      history.push("/Home/platform");
    }
  };

  const handleRecruitementPartnerClick = () => {
    if (props.isFromScholarship === false) {
      props.closeMobileNavBar();
      props.handleScrollToRecruitementPartnersProps();
    } else {
      history.push("/Home/recruitement-partners");
    }
  };

  const handleInstitutionClick = () => {
    if (props.isFromScholarship === false) {
      props.closeMobileNavBar();
      props.handleScrollToInstitutionsProps();
    } else {
      history.push("/Home/institutions");
    }
  };
  return (
    <div className="mobileNavBarContainer">
      <div className="mobileNavBarBody">
        <div className="mobileNavBarHeader w3-row">
          <div className="w3-col s6" style={{ textAlign: "left" }}>
            <img
              src={logoDark}
              alt="StartBlocksLogo"
              className="darkLogo"
              onClick={handleLogoClick}
            />
          </div>
          <div className="w3-col s6" style={{ textAlign: "right" }}>
            {" "}
            <i
              class="fa fa-times crossIcon"
              aria-hidden="true"
              onClick={() => {
                props.closeMobileNavBar();
              }}
            ></i>
          </div>
        </div>
        <div className="mobileNavBarOptionsContainer">
          <div className="mobileNavBarOption" onClick={handlePlatformClick}>
            Platform
          </div>
          <div
            className="mobileNavBarOption"
            onClick={handleRecruitementPartnerClick}
          >
            Recruitment Partners
          </div>
          <div className="mobileNavBarOption" onClick={handleInstitutionClick}>
            Institutions
          </div>
          <div className="mobileNavBarOption" onClick={handleScholarshipClick}>
            Scholarships
          </div>
          <div>
            <div>
              <button
                class="w3-button primaryButton navBarButton w3-text-white w3-block"
                onClick={handleGetInTouch}
              >
                Get in touch
              </button>
            </div>
            <div className="signInButton">
              <a
                href="https://app.startblocks.com"
                rel="noreferrer"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                Sign In
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavBar;

import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import "./HomePage.css";
import NavBar from "../NavBar/NavBar";
import CoverPage from "../CoverPage/CoverPage";
import SecondPage from "../SecondPage/SecondPage";
import ReachTrainSection from "../ReachTrainSection/ReachTrainSection";
//import LogoSection from "../LogoSection/LogoSection";
import OneFramework from "../OneFramework/OneFramework";
import ForInstitution from "../ForInstitution/ForInstitution";
import WithWithout from "../WithWIthout/WithWithout";
import OurStrength from "../OurStrength/OurStrength";
import Footer from "../Footer/Footer";
import { useEffect } from "react";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const HomePage = ({ match }) => {
  const history = useHistory();

  const topRef = useRef(null);
  const platformRef = useRef(null);
  const institutionRef = useRef(null);
  const recruitementPartnersRef = useRef(null);

  //const handleScrollToTop = () => scrollToRef(topRef);
  const handleScrollToPlatform = () => scrollToRef(platformRef);
  const handleScrollToInstitutions = () => scrollToRef(institutionRef);
  const handleScrollToRecruitementPartners = () =>
    scrollToRef(recruitementPartnersRef);

  const handleGetInTouch = () => {
    history.push(`/get-in-touch/`);
  };

  useEffect(() => {
    console.log(match.params.section);
    if (match.params.section === undefined) {
      console.log("inside undefined");
      //handleScrollToTop();
    }
    if (match.params.section === "platform") {
      handleScrollToPlatform();
    }
    if (match.params.section === "recruitement-partners") {
      handleScrollToRecruitementPartners();
    }
    if (match.params.section === "institutions") {
      handleScrollToInstitutions();
    }
  });

  return (
    <>
      <NavBar
        isFromScholarship={false}
        handleScrollToPlatformProps={handleScrollToPlatform}
        handleScrollToInstitutionsProps={handleScrollToInstitutions}
        handleScrollToRecruitementPartnersProps={
          handleScrollToRecruitementPartners
        }
      />
      <div className="darkBackgroundContainer" ref={topRef}>
        <CoverPage />
        {/*<LogoSection />*/}
        <div ref={recruitementPartnersRef}>
          <SecondPage />
        </div>
      </div>
      <div>
        <ReachTrainSection />
      </div>
      <div className="lightBackgroundContainer">
        <div ref={platformRef}>
          <OneFramework />
        </div>
        <div ref={institutionRef}>
          <ForInstitution />
        </div>
      </div>
      <div className="darkBackgroundContainer">
        <OurStrength />
        <WithWithout />
      </div>
      <div className="getInTouchContainer">
        <button
          class="w3-button primaryButton w3-block"
          onClick={handleGetInTouch}
        >
          Get in touch
        </button>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;

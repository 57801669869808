import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import axios from "axios";
import Recaptcha from "react-recaptcha";
import "./ScholarShipsForm.css";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";

const ScholarShipsForm = () => {
  const alert = useAlert();
  const history = useHistory();

  const [fullNameState, setFullNameState] = useState("");
  const [phoneNoState, setPhoneNoState] = useState("");
  const [emailState, setEmailState] = useState("");
  const [countryState, setCountryState] = useState("");
  const [courseState, setCourseState] = useState("");
  const [captchaState, setCaptchaState] = useState(false);
  const [captchaKeyState, setCaptchaKeyState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setErrorState] = useState({});

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };

  const validatePhoneNo = (phoneNo) => {
    const re = /^\d{10}$/;
    return re.test(phoneNo.toLowerCase());
  };

  const validateDetails = () => {
    const errors = {};
    if (!fullNameState) {
      errors["fullNameState"] = "Required";
    }
    if (!phoneNoState || !validatePhoneNo(phoneNoState)) {
      errors["phoneNoState"] = "Required";
    }
    if (!emailState || !validateEmail(emailState)) {
      errors["emailState"] = "Required";
    }
    if (!countryState) {
      errors["countryState"] = "Required";
    }
    if (!courseState) {
      errors["courseState"] = "Required";
    }
    if (!captchaState) {
      errors["captchaState"] = "Required";
    }
    return errors;
  };

  const handleEnquireClick = () => {
    setIsLoading(true);
    try {
      const errors = validateDetails();
      // console.log("errors - ", errors);
      if (Object.keys(errors).length !== 0) {
        setErrorState(errors);
        setIsLoading(false);
        return;
      } else {
        setErrorState({});
      }
      postScholarshipFormData();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const postScholarshipFormData = () => {
    let body = {
      recaptchaResponse: captchaKeyState,
      fullName: fullNameState,
      mobileNumber: phoneNoState,
      emailId: emailState,
      countryApplyingFor: countryState,
      levelOfcourse: countryState,
    };

    axios
      .post("https://api.start-blocks.com/landing-page/scholarship", body)
      .then((response) => {
        console.log("response - ", response);
        setIsLoading(false);
        clearTextBox();
        alert.success(
          "Thanks for showing your interest. We will get back to you shortly."
        );
      })
      .catch((error) => {
        console.log("error - ", error);
        setIsLoading(false);
        clearTextBox();
        alert.error("Something went wrong, please try again later.");
      });
  };

  const clearTextBox = () => {
    setFullNameState("");
    setPhoneNoState("");
    setEmailState("");
    setCountryState("");
    setCourseState("");
    setCaptchaState("");
  };

  const handleTextBoxChange = (e) => {
    if (e.target.name === "fullName") {
      setFullNameState(e.target.value);
    }
    if (e.target.name === "phoneNo") {
      setPhoneNoState(e.target.value);
    }
    if (e.target.name === "emailId") {
      setEmailState(e.target.value);
    }
    if (e.target.name === "country") {
      setCountryState(e.target.value);
    }
    if (e.target.name === "course") {
      setCourseState(e.target.value);
    }
  };

  const handlePrivacyClick = () => {
    history.push("/privacy-policy");
  };

  const onCaptchaChange = (response) => {
    setCaptchaKeyState(response);
    setCaptchaState(true);
  };

  return (
    <>
      <NavBar isFromScholarship={true} />
      <div className="scholarShipForm">
        <div className="scholarShipFormHeaderText">
          Signup now to know your eligibility for international scholarships
        </div>
        <div className="scholarshipForm">
          <div className="w3-row">
            <div className="w3-half scholarShipFormSection">
              <div className="formSectionHeading">What’s your name?</div>
              <input
                onChange={handleTextBoxChange}
                name="fullName"
                className={`w3-input  getInTouchFormInputBox ${
                  errorState["fullNameState"] && "textBoxError"
                }`}
                type="text"
                placeholder="Full Name"
              />
            </div>
            <div className="w3-half scholarShipFormSection">
              <div className="formSectionHeading">
                What’s your phone number?
              </div>
              <input
                onChange={handleTextBoxChange}
                name="phoneNo"
                className={`w3-input  getInTouchFormInputBox ${
                  errorState["phoneNoState"] && "textBoxError"
                }`}
                type="text"
                placeholder="Mobile No"
              />
            </div>
          </div>
          <div className="w3-row">
            <div className="w3-half scholarShipFormSection">
              <div className="formSectionHeading">What’s your email?</div>
              <input
                onChange={handleTextBoxChange}
                name="emailId"
                className={`w3-input  getInTouchFormInputBox ${
                  errorState["emailState"] && "textBoxError"
                }`}
                type="text"
                placeholder="Email ID"
              />
            </div>
            {/* <div className="w3-half scholarShipFormSection">
              <div className="formSectionHeading">
                What do you need help with
              </div>
              <select class="w3-select getInTouchFormInputBox" name="option">
                <option value="" disabled selected>
                  Choose your option
                </option>
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
                <option value="3">Option 3</option>
              </select>
            </div> */}
            <div className="w3-half scholarShipFormSection">
              <div className="formSectionHeading">Country applying for</div>
              <input
                onChange={handleTextBoxChange}
                name="country"
                className={`w3-input  getInTouchFormInputBox ${
                  errorState["countryState"] && "textBoxError"
                }`}
                type="text"
                placeholder="Country"
              />
            </div>
          </div>
          <div className="scholarShipFormSection">
            <div className="formSectionHeading">Level of the course</div>
            <input
              onChange={handleTextBoxChange}
              name="course"
              className={`w3-input  getInTouchFormInputBox ${
                errorState["courseState"] && "textBoxError"
              }`}
              type="text"
              placeholder="Level of the course"
            />
          </div>
          <div className="reCaptchaContainer">
            <div
              className={`reCaptchaInJoinNow ${
                errorState["captchaState"] && "captchaError"
              }`}
            >
              <Recaptcha
                sitekey="6Ld7RU8bAAAAAJ-LZ3N3PtWl0pHzE1AK7G1ccUbj"
                render="explicit"
                theme="dark"
                className="reCaptcha"
                verifyCallback={onCaptchaChange}
              />
            </div>
          </div>
          <div className="scholarShipFormSection">
            <div className="disclaimer">
              I agree to Startblocks terms and{" "}
              <span className="privacyPolicyLink" onClick={handlePrivacyClick}>
                privacy policy
              </span>
              . Please contact me by phone, email or SMS to assist with my
              enquiry I’d love to get useful emails from Startblocks about study
              abroad, scholarships and IELTS
            </div>
            <button
              onClick={handleEnquireClick}
              class="w3-button primaryButton w3-hide-small"
              style={{ marginTop: "1%" }}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Enquire"}
            </button>
            <button
              onClick={handleEnquireClick}
              class="w3-button primaryButton w3-block w3-hide-large w3-hide-medium"
              style={{ marginTop: "1%" }}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Enquire"}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ScholarShipsForm;

import React from "react";
import { useHistory } from "react-router-dom";
import empowerment from "../../Assets/imges/empowerment.png";
import enablement from "../../Assets/imges/enablement.png";
import train from "../../Assets/imges/train.png";
import "./ReachTrainSection.css";

const ReachTrainSection = () => {
  const history = useHistory();

  const handleGetInTouch = () => {
    history.push("/join-now");
  };

  return (
    <div className="ReachTrainSectionContainer">
      <div className="reachSection">
        <div className="forInstitutionkHeadingText">
          Reach and penetrate with larger market size
        </div>
        <div className="forInstitutionSubHeadingText">
          Is this a new market for you? Or you need a sizing opportunity to
          spread geographically? No matter what, our Reach is spread globally
          who will help you getting good quality leads.
        </div>
        <div className="w3-row" style={{ width: "100%" }}>
          <div className="w3-third" style={{ marginTop: "70px" }}>
            <div className="forInstitutionkHeadingText">Train.</div>
            <div>
              <img
                src={train}
                alt="train"
                className="trainEnableEmpowermentImage"
              />
            </div>
          </div>
          <div className="w3-third" style={{ marginTop: "70px" }}>
            <div className="forInstitutionkHeadingText">Enable.</div>
            <div>
              <img
                src={enablement}
                alt="train"
                className="trainEnableEmpowermentImage"
              />
            </div>
          </div>
          <div className="w3-third" style={{ marginTop: "70px" }}>
            <div className="forInstitutionkHeadingText">Empower.</div>
            <div>
              <img
                src={empowerment}
                alt="train"
                style={{ width: "120px", marginTop: "8%" }}
                className=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="penetrateSection">
        {/* <div className="trainEnableEmpowermentImagesSection">
          <div className="w3-row">
            <div className="w3-third">
              <img
                src={empowerment}
                alt="train"
                className="trainEnableEmpowermentImage"
              />
            </div>
            <div className="w3-third">
              <img
                src={enablement}
                alt="train"
                className="trainEnableEmpowermentImage"
              />
            </div>
            <div className="w3-third">
              <img
                src={train}
                alt="train"
                className="trainEnableEmpowermentImage"
              />
            </div>
          </div>
        </div> */}
        <div className="forInstitutionSubHeadingText">
          Even if you are new to the process, doesn’t matter! StartBlocks is
          pre-configured with step-by-step process to train you for a particular
          geography, certify you with the badge; and can help you get to the
          speed so you can be the new face of student recruitment.
        </div>
        <div className="joinNowSection">
          <div className="forInstitutionSubHeadingText" style={{ opacity: 1 }}>
            Earn your badge now!{" "}
          </div>
          <div>
            {" "}
            <button
              class="w3-button primaryButton"
              onClick={handleGetInTouch}
              style={{ marginTop: 10 }}
            >
              Join
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReachTrainSection;

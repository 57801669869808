import React from "react";
import "./SecondPage.css";
import SecondPageImage from "../../Assets/imges/secondImage.png";
import SecondPageImageMobile from "../../Assets/imges/secondImageMobile.png";

const SecondPage = () => {
  return (
    <div className="secondPageContainer">
      <div className="secondPageHeader">
        <div className="sectionHeading">Recruitment partners</div>
        <div className="secondPageHeadingText">
          Streamline your journey of application processing
        </div>
        <div className="secondPageSubHeadingText">
          StartBlocks works on a proprietary algorithm to speed up the
          processing and turnaround time of your student application increasing
          the rate to go-to-market by at-least 50%
        </div>
      </div>
      <div className="secondPageBody">
        <div className="w3-row">
          <div className="secondPageBodyImageContainer w3-col l9 m12 s12">
            <img
              src={SecondPageImage}
              alt=""
              className="SecondPageImage w3-hide-small w3-img w3-hide-medium"
            />
            <img
              src={SecondPageImageMobile}
              alt=""
              className="SecondPageImage w3-hide-large"
            />
          </div>
          <div className="secondPageTextBesideImage w3-col l3 m12 s12">
            <div className="textBesideImageContainer">
              <div className="sectionText">
                <div className="headingText">Auto verification</div>
                <div className="subHeadingText">
                  Auto- verify all possible documents with peace of mind
                  avoiding any manual errors{" "}
                </div>
              </div>
              <div className="sectionText" style={{ marginTop: "5%" }}>
                <div className="headingText">Collaborative work</div>
                <div className="subHeadingText">
                  Work alongside the backend processing teams with assist touch{" "}
                </div>
              </div>
              <div className="sectionText" style={{ marginTop: "5%" }}>
                <div className="headingText">Risk Management</div>
                <div className="subHeadingText">
                  Reduce the risk with automated workflows, processes and
                  checklists ensuring high visa success rate{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondPage;
